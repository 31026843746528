<template>
  <figure class="game-center-item" :class="className">
    <div class="cursor-pointer" @click="openGame(game)">
      <div class="game-center-item__box">
        <div class="game-center-item__box--image">
          <BaseImg :src="game.imageUrl" class="game-list--top" lazy :alt="game.title" />
        </div>
        <div class="game-center-item__box--title">
          {{ game.title }}
        </div>
        <div class="game-center-item__box--des">
          {{ game.description }}
        </div>
      </div>
    </div>
  </figure>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { PAGE } from '~/constants/router'
import BaseImg from '~/components/common/base-img.vue'
import { IGameCenterItem } from '~/resources/game'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot } = storeToRefs(store)
const router = useRouter()

withDefaults(defineProps < { game: IGameCenterItem, className: string, isShowIcon?: Boolean } >(), {
  game: () => ({
    title: '',
    imageUrl: '',
    description: '',
    link: '',
    isReturn: false
  }),
  className: () => '',
  isShowIcon: () => false
})

const openGame = (item: IGameCenterItem) => {
  if (item.isReturn) {
    if (currentUser) {
      router.push(`${PAGE.REWARD}?tabIndex=1`)
    } else {
      router.push(`${PAGE.PROMOTION}?event=event-common-1`)
    }
  }
  if (item.link) {
    router.push(item.link)
  }
}
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/game-center/game-center-item.scss"></style>
