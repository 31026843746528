<template>
  <nuxt-link :to="`/${item.alias}`" class="news-section-item">
    <div class="news-section-item__content">
      <div class="news-section-item__title">{{ item.title }}</div>
      <div class="news-section-item__desc">{{ item.desc }}</div>
    </div>
    <div class="news-section-item__image">
      <BaseImg :src="item?.thumbnail" :alt="item.title" />
    </div>
    <BaseButton class="btn" @click="router.push(`/${item.alias}`)">
      Chi Tiết
    </BaseButton>
  </nuxt-link>
</template>

<script setup>
import dayjs from 'dayjs'
import BaseButton from '~/components/common/base-button.vue'
const router = useRouter()
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  item: {
    type: Object,
    default: () => {}
  }
})

const getTime = () => {
  const time = dayjs(props.item?.created_time)
  return time ? `${time.format('hh')}h${time.format('mm')} Ngày ${time.format('DD/MM')}` : ''
}
</script>
<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/news/news-section-item.scss';
</style>
