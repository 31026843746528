<template>
  <div class="jackpot-animated-number" :style="`min-width: ${contentWidth}px`">
    <div class="container">
      <div class="layer1" />
      <div class="layer2" />
      <div class="layer3" />
      <div class="layer-haft-bottom" />
      <span class="animation-number masked">{{ displayNumber }}</span>
      <span class="animation-number number">{{ displayNumber }}</span>
    </div>
  </div>
  <div v-if="showComma" style="margin: 38px 6px 0">
    <BaseImg src="/assets/images/common/icon-comma.svg" />
  </div>
</template>
<script setup>
import BaseImg from '~/components/common/base-img.vue'

const props = defineProps({
  number: {
    type: [Number, String],
    default: 0
  },
  fromNumber: {
    type: Number,
    default: 0
  },
  showComma: {
    type: Boolean,
    default: false
  },
  currentIndex: {
    type: Number,
    default: 0
  }
})

const displayNumber = ref(props.fromNumber ? props.fromNumber : 0)
const interval = ref(false)

const contentWidth = ref(0)

const isFirstItem = props.currentIndex === 0

watch(props.number, () => {
  handleNumber()
})

onMounted(() => {
  if (isFirstItem) {
    contentWidth.value = getContentWidth(props.number.length)
  } else {
    contentWidth.value = 150
  }
  handleNumber()
})

const watchNumber = () => {
  clearInterval(interval.value)
  if (props.number === displayNumber.value) {
    return
  }
  interval.value = window.setInterval(() => {
    if (displayNumber.value !== props.number) {
      let change = (props.number - displayNumber.value) / 10
      change = change >= 0 ? Math.ceil(change) : Math.floor(change)
      const data = displayNumber.value + change
      displayNumber.value = data
      if (parseInt(displayNumber.value) === parseInt(props.number)) {
        clearInterval(interval.value)
        displayNumber.value = padToThreeChars(`${displayNumber.value}`)
      }
    }
  }, 35)
}

function padToThreeChars(str) {
  if (isFirstItem) {
    return str
  } else {
    return str?.padStart(3, '0')
  }
}

const getContentWidth = () => {
  switch (`${displayNumber.value}`.length) {
    case 1:
      return 50
    case 2:
      return 100
    default:
      return 150
  }
}

const handleNumber = () => {
  if (props.number === '000') {
    displayNumber.value = props.number
    return
  }

  const convertNumber = parseInt(props.number)
  if (!isNaN(convertNumber)) {
    watchNumber()
  } else if (props.number === 'VND') {
    displayNumber.value = props.number
  } else {
    displayNumber.value = padToThreeChars(`${props.number}`)
  }
}
</script>

<style scoped lang="scss" src="assets/scss/components/common/jackpot-animated-number.scss"></style>
