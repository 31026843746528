<template>
  <div
    :id="item.name"
    class="slot-game-item effect-hover"
    :class="{ maintain: item.maintain }"
    @click="handleOpenGame(item)"
  >
    <div class="item effect-hover">
      <div class="slot-game-item__thumbnail-wrapper">
        <CommonBaseImg
          :id="`${item?.name}-image`"
          :src="item?.img"
          :alt="item?.name"
          lazy
          class="slot-game-item__thumbnail"
        />
        <div v-if="jackpot(item.partner_game_id)" class="slot-game-item__jackpot">
          <BaseImg :src="'/assets/images/components/common/icon-jackpot.svg'" alt="iconsPrice" />
          <AnimatedNumber :number="jackpot(item.partner_game_id)" />
        </div>
      </div>
      <div class="slot-game-item__hover">
        <div class="game-content">
          <p class="game-play">
            <BaseButton class="btn" @click="handleOpenGame(item)">
              Chơi Ngay
            </BaseButton>
          </p>
        </div>
      </div>
    </div>
    <CommonBaseImg v-if="item.maintain" class="slot-game-item__maintain" :src="CASINO_PATH + 'tag-maintain.svg'" />
  </div>
</template>

<script setup>
import CommonBaseImg from '~/components/common/base-img'
import AnimatedNumber from '~/components/common/animate-number.vue'
import useNavigationGame from '~/composables/game/useNavigationGame'
import { CASINO_PATH } from '~/constants/path'
import { useAppStore } from '~/store/app'
import BaseButton from '~/components/common/base-button.vue'
const { $pinia } = useNuxtApp()

const store = useAppStore($pinia)

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const jackpot = (gameid) => {
  if (gameid) {
    return store.jackpot[gameid]
  }
  return null
}

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()

const handleOpenGame = (item) => {
  navigationCheckLoggedInAndOpenGame(item)
}
</script>

<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/jackpot/slot-game-item.scss';
</style>
